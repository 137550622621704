<template>
    <div>
        <component :is="currentComponent + 'Component'"/>
    </div>
</template>

<script>
export default {
  components: {
    createComponent: () => import('@/components/advertiser/CreateAdvertiser')
  },
  data () {
    return {
      currentComponent: 'create'
    }
  },
  methods: {
  }
}
</script>
